.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #0879da;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/*customized style*/
body::-webkit-scrollbar {
  width: 0px;
}
body, p{color: #383636;}
p
{
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
}
button:focus {
  outline: 0px;
}
.cursor{cursor: pointer;}
.ant-input:focus
{
  box-shadow: none;
  outline: 0px;
  border-color: #d9d9d9;
}
.ant-select-selection__rendered:focus {
  outline: 0px;
}
/* .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none;
  box-shadow: none;
} */
.dflex
{
  display: flex;
  align-items: center;
}
/* header */
.ant-menu-horizontal.menuhr-none
{
  border-bottom: 0px;
}
.ptb-5
{padding: 5px 0px;  height: 53px;}
.ptb-admin
{padding: 5px 0px;  height: 38px;}
.d-flex
{
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: flex-end;
}
.gx-sidebar-notifications {
  float: left;
  width: 55%;
}
.ant-layout-header {
  width: 100%;
  position: fixed;
  height: 64px;
  padding: 0 0px 0px 60px !important;
  line-height: 64px;
  background: #0879da !important;
  z-index: 9;
}
.ant-layout-sider {
  background: #fff !important;
}
.ant-layout {
  background: #ffffff !important;
}
.gx-layout-sider-header {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0879da;
}
span.gx-avatar-name {
  color: #fff;
  margin-left: 7px;
}
.ant-avatar-circle.ant-avatar-image {
  margin-right: 5px;
}
.dpdn-color a
{
  color: #55616c !important;
}
.ant-message-notice:first-child {
  margin-top: 40vh !important;
}
.ant-layout-sider-children
{
  position: fixed;
  height: 100vh !important;
}
.notes-div{
  margin-top: 30px;
  padding-left: 0px;
}
.resmenu i.anticon.anticon-menu.d-block {
  color: #fff;
  margin-left: 20px;
  font-size: 20px;
}
/*changes*/
.resmenu {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -40px;
  left: 0;
}

/* end header */

/* footer */
.ant-layout-footer {
  padding: 10px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #f0f2f5;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.gx-main-content-wrapper {
  margin-bottom: 80px;
}

/* footer */
.p-0{padding: 0px !important;}
.p-10{padding: 10px;}
.p-20{padding: 20px;}
.pt-0{padding-top: 0px;}
.pb-0{padding-bottom: 0px;}
.pl-0{padding-left: 0px;}
.pr-0{padding-right: 0px;}
.pt-10{padding-top: 10px;}
.pb-10{padding-bottom: 10px;}
.pl-10{padding-left: 10px;}
.pr-10{padding-left: 10px;}
.pt-20{padding-top: 20px;}
.pb-20{padding-bottom: 20px;}
.pl-20{padding-left: 20px;}
.pr-20{padding-right: 20px;}

.m-0{margin: 0px;}
.m-10{margin: 10px;}
.m-20{margin: 20px;}
.mt-0{margin-top: 0px;}
.mb-0{margin-bottom: 0px !important;}
.ml-0{margin-left: 0px;}
.mr-0{margin-right: 0px;}
.mt-10{margin-top: 10px !important;}
.mb-10{margin-bottom: 10px;}
.ml-10{margin-left: 10px;}
.mr-10{margin-right: 10px;}
.mt-20{margin-top: 20px;}
.mb-20{margin-bottom: 20px;}
.ml-20{margin-left: 20px;}
.mr-20{margin-right: 20px;}
.mb-40{margin-bottom: 40px;}

.mr-5{margin-right: 5px;}
.ml-5{margin-left: 5px;}

stong {
  font-weight: 700;
  font-size: 12px;
  color: #333;
}
.form-group
{
  margin-bottom: 15px;
}

.has-error .log-formcontrol {
  border-color: #f5222d !important;
}
.h-38{height: 38px;}
 /* custom button */

 .ant-drawer-body
 {
  padding: 0px !important;
 }

.ant-btn.ant-btn-primary
{
  background: #0879da;
  transition: all linear .3s;
  border: 1px solid #0879da;
  /*padding: 7px 25px;*/
  border-radius: 5px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  color: #fff;
}

.ant-btn.share_icon.ant-btn-cancel {
  background: #ccc;
  border-color: #ccc;
}
.ant-btn.ant-btn-success {
  background: #00e89d;
  border-color: #00e89d;
  color: rgba(0, 0, 0, 0.65);
}

.ant-btn.cancel_icon.ant-btn-cancel {
  color: #ffffff;
  background-color: #d9534f;
  border-color: #d43f3a;
  border-radius: 5px;
}

.btn-close.ant-btn-cancel {
  background: #ddd;
  color: #000;
}
.btn-close.ant-btn-cancel:hover {
  background: #ddd;
  color: rgba(0, 0, 0, 0.65));
}
.ant-btn.ant-btn-success:hover
{
  background: #00e89d;
  border-color: #00e89d;
  color: rgba(0, 0, 0, 0.65);
}
/* end custom button */

.ck.ck-editor {
    width: 100% !important;
}
.text-center{text-align: center;}
.text-left{text-align: left;}
.text-right{text-align: right;}
.float-left{float: left;}
.float-right{float: right;}
.text-white{color: #fff;}
.black-text{color: #383636;}
.p-100{padding: 100px 0px 100px 0px;}
.mt-19{margin-top: 19%;}
.w-100{width: 100%;}
.mw-100{max-width: 100%;}
.grey-clr-bg{background: #f8f8f8;}
.easy-note{box-shadow: 0px 1px 49px 5px #5d5d5d1f;}
.para-graph
{
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  text-align: justify;
  text-transform: uppercase;
  margin: 10px 0px 30px 0px;
}
.ban-img
{
  position: relative;
  display: block;
  max-width: 100%;
  height: auto;
}
.ban-caption {
  position: absolute;
  top: 40%;
  left: 10%;
  text-align: left;
}
.title-style{font-size: 40px; font-weight: 600; margin: 0px; text-transform: uppercase;}
.ban-para
{
  color: #fff !important; 
  width: 55%;
  font-size: 17px;
  line-height: 32px;
}
.ban-btn {
  background: #0879da !important;
  border-radius: 33px !important;
  color: #fff !important;
  padding: 0px 23px !important;
  border-color: #0879da !important;
}
.btm-imgstyle{background: #fff; padding: 40px 0px;}
.blue-back{background: linear-gradient(to bottom,#0879da 50%,white 50%,white 100%); padding: 100px 0px 0px 0px;}
.blue-back-full{background: #0879da; padding: 100px 0px 100px 0px;}
.blue-title{font-size: 30px; text-align: center; text-transform: uppercase; color: #fff !important;}
.pgraph-style{font-size: 17px; text-align: center; color: #fff; padding: 20px 0px 20px 0px;
  margin: 0px 200px;}
.sec-border{border-top: 1px solid #fff; margin-top: 40px;}
.mt-minus {
  margin-top: -120px;
}
.testi-para {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0;
  color: #fff;
  margin: 0px;
}
.testi-text {
  font-size: 14px;
  padding: 0 40px;
  font-weight: 300;
  line-height: 18px;
  color: #fff;
}
.black-title {
  font-size: 31px;
  text-transform: uppercase;
  color: #383636 !important;
}
.learn-btn {
  text-transform: uppercase;
  color: #0879da !important;
  padding: 0px 18px !important;
  border-radius: 39px !important;
  transition: all linear .3s !important;
  border: 1px solid #0879da !important;
  background: none !important;
}
.foot-text
{
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 15px;
  text-transform: uppercase;
}
.bk_img{
    background: url(./assets/images/login_bg.png);
    color: #333;
    padding: 200px 45px 60px;
    background-size: cover;
}
.white-bg
{
  background: #ffffff54;
  padding: 20px;
  border-radius: 4px;
}
.log-formcontrol
{
  border-radius: 50px !important;
  margin-bottom: 10px !important;
  color: #666 !important;
  padding: 0 20px !important;
  outline: none !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  height: 45px !important;
  /* text-transform: uppercase; */
}
.log_form_style .ant-row.ant-form-item {
  margin-bottom: 0px;
}
.ant-btn.sub_mit
{
    background: #0879da ;
    padding: 15px;
    width: 100%;
    color: #fff;
    margin: 0px 0 15px;
    border: none;
    height: 50px !important;
    border-radius: 50px !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-size: 17px !important;
}
.log_para
{
  text-align: center;
  margin: 70px 0 30px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.grey-bg
{
    background: #3c3a3a;
    color: #fff;
    padding: 20px 0 20px;
    font-size: 13px;
    float: left;
    width: 100%;
}
.s-img {
  background: #0879da;
  }
  .tri-steps{z-index: 9;}
  .mt-minus.ant-divider-horizontal {
    margin-top: -125px;
}
/*footer*/
.copy-right
{
  font-size: 11px;
  line-height: 40px;
  color: #fff;
}

.App .grey-bg {
  text-align: left;
}
.App .foot-title {
  color: #fff;
  font-weight: 600 !important;
  text-transform: uppercase;
  border-bottom: 1px solid #504e4e;
  margin-bottom: 10px !important;
  padding-bottom: 7px;
}
.foot_1 p {
  font-size: 14px;
  color: #fff;
}
.foot_1 p a {
  color: #fff;
}
.img-space {
  margin-right: 8px;
}
.grey-bg p{margin: 0px; line-height: 24px;}
.log_para a {
  color: #b59aff;
}
/*end footer*/

/*footer bottom*/
.lightgrey-bg
{
  background: #a7a6a6;
  padding: 12px 0;
  text-align: left;
  color: #3c3a3a;
  font-size: 13px;
  float: left;
  width: 100%;
}
.icon-fb {
  margin: 0px 8px;
}
.icon-twt {
  margin: 0px 8px;
}
.foot-btm p {
  margin: 0px;
}
.foot-btm p:first-child {
  margin-right: 10px;
}
/*end footer bottom*/
/*end customized style*/

/*login page*/
.login_bk_img{
  background: url(./assets/images/login-page.jpeg);
  color: #333;
  padding: 80px 45px 80px 60px;
  background-size: cover;
  float: left;
  width: 100%;
  background-position: center center;
} 
.white_bg
{
  color: #3b3e47;
  float: none;
  background: rgba(246, 246, 246, 0.7);
  box-shadow: 0px 0px 16px #d4d2d2;
  font-size: 12px;
  float: left;
  padding: 0 !important;
}
.ant-form.white_bg_inner
{
  padding-left: 30px;
  padding-top: 50px;
  font-size: 12px;
  overflow: hidden;
  padding-right: 30px;
}
.log-text a:first-child {
  float: left;
  color: #3b3e47;
}
.log-text a {
  float: right;
  color: #3b3e47;
}
.log-para
{
  background: #f5f5f5 none repeat scroll 0 0;
  font-size: 12px;
  margin-top: 20px;
  overflow: hidden;
  margin-bottom: 0px;
  padding: 25px;
}
.log-title
{
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 0;
}
.ant-typography.log-title {
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 0px 0px 0px;
}
/*end login page*/

/* innersidemenu */
.ant-menu.innersidemenu {
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fafbfc;
  text-align: left;
  overflow-y: auto;
}
.ant-menu.innersidemenu .ant-menu-item {
  padding: 0px 40px;
  margin: 0px !important;
}
.sideinnertext
{
  text-align: left;
  color: #121212 !important;
  font-weight: 700;
  font-size: 20px;
  margin: 20px 0px;
}
.blue-btn {
  background: #0879da;
  transition: all linear .3s;
  border: 1px solid #0879da;
  padding: 8px 10px;
  border-radius: 5px;
  font-weight: 600;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  cursor : pointer;
}
/* .ant-btn.share_icon.ant-btn-primary {
  padding: 0px 25px;
} */
.ant-btn .anticon + span {
  margin-left: 4px !important;
}
.white-btn {
  background: #fff;
  color: #3f4d58;
  padding: 8px 10px;
  border: 1px solid #d9d9d9;
  transition: all linear .3s;
  border-radius: 5px;
  font-weight: 600;
  display: inline-block;
  align-items: center;
  vertical-align: middle;
  background-position: 21px 9px;
  cursor : pointer;
}
i.anticon.anticon-folder-add {
  margin-right: 5px;
}
i.anticon.anticon-upload {
  margin-right: 5px;
}
.flex {
  display: flex;
  align-items: center;
}
.text-right {
  width: 100%;
  text-align: right;
}
.liimg {
  height: auto;
  max-width: 100% !important;
  width: 30px;
  max-height: 38px;
  box-shadow: 0px 2px 9px -3px #afafaf;
  border-radius: 4px;
}
ul.single_ul{
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  list-style-type: none;
  cursor: pointer;
}
ul.single_ulend {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0px;
  justify-content: flex-end;
  margin: 0px;
}
.tab-li {
  margin-bottom: 0px;
}
.single-li .anc-text 
{
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  color: #404448;
}
.single-para {
  margin-top: -5px;
  font-size: 10px;
  color: #8d9398;
  font-weight: 600;
  margin-bottom: 1px;
}
.li-img
{
  /* width: 50px; */
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-right: 7px;
}
.w-30
{
  width: 30px;
  height: 30px;
}
.dp-btn .ant-btn.ant-btn-default:first-child {
  display: none;
}
.dp-btn .ant-btn {
  border: 0px;
  box-shadow: none;
  height: 22px;
  background: transparent;
}
.table_design tbody tr, th
{
  border-bottom: 1px solid #f2f2f2;
  padding: 0px 0px 10px 0px;
}
.ptb-10{padding: 10px 0px;}
.single_ulend .anticon svg {
  color: rgba(0, 0, 0, 0.65);
}
.table_design td {
  padding: 10px 0px;
}
/* end innersidemenu */

/* breadcrumbs */
.ant-breadcrumb {
  text-align: left;
  color: #121212 !important;
  font-weight: 700;
  font-size: 20px;
  margin: 20px 0px !important;
}
.ant-breadcrumb a
{font-weight: normal;}
/* end breadcrumbs */

/* upload drag and drop */
.up_load_btn .ant-upload.ant-upload-select.ant-upload-select-picture {
  width: 100%;
}
.up_load_btn .ant-upload span.ant-upload {
  display: block;
  text-align: center;
  border: 2px dashed #0879da;
  align-items: center;
  justify-content: center;
  max-height: 180px;
  height: 180px;
  margin: 10px 0px;
  padding: 10px 0px;
}
.fs-38.anticon-cloud-upload
{
  font-size: 38px;
  color: #4e5adc;
}
.up_load_btn h3 {
  color: #0879da;
  font-size: 18px;
  margin: 10px 0px;
}
.up_load_btn {
  border: 2px dashed #0879da;
  text-align: center;
  padding: 20px 0px;
}
.text-or{
  color: #0879da;
  font-size: 13px;
  display: block;
  margin: 10px 0px;
}
.up_load_btn input[type="file"]::-webkit-file-upload-button {
  display: inline-block;
    padding: 8px 14px;
    cursor: pointer;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    color: #0879da;
    border-radius: 3px;
    border: 1px solid #0879da;
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(0,0,0,0.05);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    width: 100%;
}
input.up_input_btn[type='file'] {
  display: block;
  margin: 0 auto !important;
}
/* end upload drag and drop */

/* frontresmenu*/
.frontresmenu .ant-menu-item a {
  color: #55616c;
}
.frontresmenu {
  height: 100vh;
}
.mob-dis-show img {
  display: none;
}
.ant-menu-item-active{border-bottom: transparent !important;}
.ant-menu-horizontal > .ant-menu-item{border-bottom: 0px !important;}

/*end frontresmenu*/

/*helpsupport*/
.search-bk
{
  min-height: 278px;
  background-color: #a0a3b8;
  padding-top: 78px;
}
.search-sp {
  background-color: #0879da;
  padding: 20px 0px;
}
.search-sp h4.ant-typography {
  margin-top: 0px; font-weight: 500;
}
.search-box
{
  max-width: 510px;
  margin: auto;
}
.search-box input.ant-input {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 9.6px 0.4px rgba(0, 0, 1, 0.12);
  max-width: 505px;
  height: 42px;
  border-radius: 25px;
}
.search-title.ant-typography
{
  font-size: 23px;
  color: rgb(251, 251, 251);
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 30px;
  font-weight: 500;
}
.icon-style.anticon-menu
{
  color: #494076;
  font-size: 18px;
  margin-right: 10px;
}
.search-subtitle.ant-typography
{
  font-size: 20px;
  color: rgb(0, 0, 0);
  padding: 20px 0px 0px 0px;
  margin-bottom: 0;
  font-weight: 500;
}
.ul-style li {
  text-align: left;
  margin-bottom: 20px;
}
.ul-style li a {
  font-size: 13px;
  color: rgb(75, 75, 75);
}
.ul-style ul {
  padding-left: 15px;
}
.col-p p
{
  font-size: 13px;
  color: rgb(75, 75, 75);
  line-height: 1.692;
}
.bread-crumb .ant-breadcrumb a {
  color: #BBBAB8;
}
.bread-crumb span{color: #BBBAB8;}
.bread-crumb span:last-child{color: #fff;}
.ant-breadcrumb > span:last-child a {
  color: rgb(255, 255, 255);
}
.ant-breadcrumb {
  margin: 20px 10px;
}
/*end helpsupport*/

/*ebox*/
.ques-bk
{
  padding: 25px;
  background-color: #f1f1f1;
  margin-top: 30px;
  height: 360px;
}
.ques-title.ant-typography
{
  font-size: 14px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 20px;
}
.ques-li {
  padding-left: 20px;
  list-style: none;
}
.ques-li li a {
  color: rgb(124, 124, 124);
  font-size: 13px;
}
.ques-li li {
  margin-bottom: 10px;
}
.ques-icon
{
  color: #494076;
  font-size: 14px;
  margin-right: 10px;
}
.search-btm img {
  box-shadow: 0px 1px 9.6px 0.4px rgba(0, 0, 1, 0.12);
  margin-bottom: 15px;
}
/*end ebox*/

/*profile*/
.white-bk
{
  background: #fff;
}
.ant-typography.h2-title {
  color: #323232;
  font-weight: 600;
  font-size: 30px;
}
.w-100.ant-input-number {
  width: 100%;
}
.profile-set .ant-input {
  border-radius: 6px !important;
  margin-bottom: 10px !important;
  color: #55616c;
  padding: 0 20px !important;
  outline: none !important;
  border: 1px solid #f2f2f2;
  box-shadow: none;
  height: 40px;
  background: #f8f8f8 none repeat scroll 0 0;
}
.ant-input-number.num-design
{
  border-radius: 6px !important;
  margin-bottom: 10px !important;
  color: #55616c;
  padding: 0 10px !important;
  outline: none !important;
  border: 1px solid #f2f2f2;
  box-shadow: none;
  height: 40px;
  background: #f8f8f8 none repeat scroll 0 0;
}
.num-design .ant-input-number-input {
  height: 40px;
}
.mt-60{margin-top: 60px;}
.ant-btn.prof-blue-btn
{
  background: #0879da;
  transition: all linear .3s;
  border: 1px solid #0879da;
  padding: 7px 25px;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  height: 40px;
}
.d-block .ant-upload.ant-upload-select.ant-upload-select-text {
  display: block;
}
.ant-btn.upload-btn {
  /*border-radius: 6px !important;
  color: #55616c;
  padding: 0 20px !important;
  outline: none !important;
  border: 1px solid #55616c;
  box-shadow: none;
  height: 40px;*/

  background: transparent;
  border: none;
  color: #ff0000;
  font-size: 12px;
  padding: 0px;
  width: 72px;
  height: 56px;
  margin-top: -11px;
}
.ant-btn.upload-btn:hover, .ant-btn.upload-btn:active, .ant-btn.upload-btn:focus {
  background: none !important;
  color: #ff0000;
}
.prof-img {
  max-width: 100% !important;
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 100%;
}

.prof_btn
{
  display: block !important;
  height: 38px !important;
}
.mtb-30
{
  margin-top: 10px;
  margin-bottom: 30px;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #e6f7ff;
  width: 300px;
}
.ant-upload-list-item-info > span {
  width: 300px;
}
.space_less_form .ant-form-item
{
  margin: 0px;
}
/*end profile*/

/* reportbug */
.ant-btn.rep-black-btn.ant-btn-button {
  background: #3e3d42 none repeat scroll 0 0;
  text-transform: uppercase;
  border: medium none;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  transition: all linear .3s;
  width: 100%;
  border-radius: 50px;
  height: 40px;
}
.repbug-bk-color {
  color: #3b3e47;
  background: rgba(246, 246, 246, 0.7);
  box-shadow: 0px 0px 16px #d4d2d2;
  margin-top: 64px;
  font-size: 12px;
}
.ant-typography.rep-title
{
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
}
.rep-header
{
  font-size: 12px;
  overflow: hidden;
  padding: 50px 30px;
}
.rep-header input.ant-input {
  padding: 15px;
  width: 100%;
  border-radius: 50px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  height: 40px;
}
.rep-header textarea.ant-input {
  padding: 15px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}
/* end reportbug */

/*userbymail*/
.ant-modal-footer {
  border-top: 0px !important;
}
.ant-modal-header {
  background: #0879da !important;
}
.ant-modal-title
{color: #fff !important; font-weight: 600;}
.ant-modal-close-x
{color: #fff !important; font-weight: 600;}
.notes-title
{
  color: #9aa1a6;
  font-weight: 700;
  font-size: 13px;
  padding-bottom: 4px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 10px;
  margin-top: 15px;
}
/*userbymail*/
.tui-image-editor-header-logo{
  display: none;
}
/*notes*/
.notes-ul {
  padding: 0px;
  list-style-type: none;
  display: inline-flex;
  margin: 0px;
  align-items: center;
}
.notes-ul li {
  margin: 0px 20px;
}
.notes-div {
  text-align: end;
}
.yellow-clr.anticon{color: #f7b94d; font-size: 12px;}
.blue-clr.anticon
{
  color: #0879da; 
  font-size: 12px;
  margin-right: 5px;
}
.notes-ul a {
  color: #333;
  font-size: 12px;
}
.notes-ul a {
  color: #333;
  font-size: 12px;
  display: flex;
  padding: 2px 7px;
  cursor: pointer;
  border-radius: 4px;
  transition: all linear .3s;
  align-items: center;
}
.cust-detail
{
  float: left;
    width: 100%;
}
/*ckeditor*/
.modal-imgs .image img {
  max-width: 100%;
}
.c-sec img {
  max-width: 100%;
}
.color-picker-container {
  position: relative;
}
.color-picker-palatte {
  position: absolute;
  z-index: 100;
}
.color-picker-cover {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
.color-picker-color-background {
  position: fixed;
  height: 35px;
  width: 35px;
  background-color: #999999;
  float: left;
}
/**/
.div-sec {
  padding: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  transition: all linear .3s;
  margin-bottom: 15px;
  min-height: 300px;
}
.div-sec .m-title {
  font-size: 12px;
  color: #878787;
  font-weight: 600;
}
.div-sec .div-title {
  display: inline-flex;
  align-items: center;
  color: #58646e;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 15px;
  max-width: 100%;
  overflow: hidden;
  max-height: 20px;
}
.div-sec .c-sec {
  color: #878787;
  font-weight: 500;
  font-size: 14px;
  height: 104px;
  overflow: hidden;
}
.c-sec p div p {
  height: 10px;
  max-width: 100%;
  font-size: 12px;
  margin-bottom: 10px;
}
.sub-div {
  height: 18px;
}
.notes-block:hover .book_icon {
  display: block;
}
.notes-block .book_icon {
  display: none;
}
.bk-icon
{
  font-size: 14px;
  margin-left: 6px;
  transition: all linear .3s;
}
.tri-icons {
  list-style-type: none;
  display: inline-flex;
  padding: 0px;
  margin: 0px;
  width: 100%;
}
.tri-icons li {
  margin: 0px 5px;
}
.notes-block .tri-icons {
  display: none;
}
.notes-block:hover .tri-icons {
  display: inline-flex;
}   
.notes-block:hover .div-sec {
  box-shadow: 1px 1px 6px 0px #b5b5b5;
}
.notes-block1:hover .div-sec{
  box-shadow: 1px 1px 6px 0px #b5b5b5;
}

.ant-modal.n-width {
  width: 1000px !important;
}
.ant-modal.full-width {
  width: 100% !important;
  margin: 0px !important;
  top: 0px !important;
}
.mod-height .ant-modal-body {
  height: calc(100vh - 160px);
}
i.anticon.anticon-fullscreen {
  cursor: pointer;
}
.mt-64
{
  margin-top: 64px;
}
.mt-15{margin-top: 15px;}
/**/
.po-fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}
/*notes*/

/* ckeditor */
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-border);
  height: 320px;
}
.ck.ck-editor__main>.ck-editor__editable
{
  height: 320px;
}
/* end */

.be_check {
  position: absolute;
  right: 5px;
  top: 0;
  display: none;
}
.list-img:hover .be_check {
  display: inline;
}
.be_check .ant-checkbox:hover::after, .be_check .ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden;
}
.be_check .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4e5ddc00;
  border-color: #4e5ddc0f;
  background: url(https://hexanotes.com/images/check-su.png);
  background-size: contain;
  outline: none;
  /* border: none; */
}
.be_check .ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  /* border-radius: 2px; */
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: '';
  background: url(https://hexanotes.com/images/check-su.png);
  background-size: contain;
}
.be_check .ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 0px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: url(https://hexanotes.com/images/check-su2.png) no-repeat;
  background-size: contain;
}

/* photos */
.list-img
{
    float: left;
    position: relative;
    width: 100px;
    height: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 4px;
}
.anchor-color
{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.anchor-color a
{
  color: #333333;
  font-size: 12px;
}
.anchor-color a i
{
  margin: 0px 3px;
}
.first-block {
  display: block;
  border-bottom: 1px solid #f5f5f5;
  padding: 0px 0px 5px;
  color: #55616c;
  font-size: 11px;
  font-weight: 700;
}
.list-img img {
  cursor: -webkit-zoom-in;
}
ul.album-list {
  list-style-type: none;
}
/* end photots */

/* albums */
.gallery-sec
{
  width: 145px;
  height: 170px;
  border: 1px solid #f2f2f2;
  border-radius: 3px;
  padding: 2px;
  float: left;
  margin: 0 2px 5px;
}
.list-img1
{
  background: #ccc;
  height: 128px;
  width: 100%;
}
.list-img1 img {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  cursor: auto;
}
.gallery-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
}
.gallery-caption p
{
  margin: 0px;
}
.gallery-caption a {
  color: #333333;
}
/* end albums */

/* insights */
.bg_white {
  background: #fff;
  box-shadow: 0px 0px 17px #ccc;
  padding: 20px;
  border-radius: 3px;
}
.flex-sp-btwn
{
  display: flex;
  justify-content: space-between;
}
.text-style {
  font-size: 13px;
  color: #a7a7a7;
}
.num-style {
  font-weight: 400;
  color: #000;
  font-size: 20px;
}
.h-75{max-height: 75vh; height: 75vh;}
.h-100{max-height: 100vh; height: 100vh;}
/* end insights */

/* members */
.grey_round{
  border: 1px solid #ece9e9;
  padding: 5px;
  border-radius: 100%;
}
.h-38 .ant-select-selection--single {
  height: 38px;
}
.d_flex {
    display: inline-flex !important;
    vertical-align: middle !important;
}
.members-ul li{margin-left: 0px;}
.btm_bdr{ border-top: 1px solid #ddd;}
/* end members */

/* billing */
.inner_sp li a {
  margin-right: 10px;
}
.inner_sp li:first-child {
  margin-right: 5px;
  display: block;
}

.inner_sp li a:after {
  content: "";
  position: absolute;
  height: 0.9em;
  border-right: 1px solid #339dff;
  margin: 0px 5px;
  top: 5px;
}
.inner_sp li:nth-child(2) a:after {
  border-right: 0px !important;
}
.inner_sp li:nth-child(3) a:after {
  border-right: 0px !important;
}
/* end billing */

/* end photos */

@media only screen and (min-widht: 768)
{
.d-block{display: block !important;}
.d-none{display: none !important;}
}

@media only screen and (max-width: 767px)
{
.blue-btn .d-none{display: none;}
.white-btn .d-none{display: none;}
.mediablock{display: block;}
.ant-drawer-body{padding-left: 100px; padding-right: 0px; padding: 0px;}
.d-block{display: block !important;}
.d-none{display: none !important;}
.single-li .anc-text {font-size: 12px;}
.sideinnertext {margin: 20px 0px 20px 0px;}
/*.white-btn {padding: 7px 10px;}*/
.mediapr-0{padding-right: 0px !important;}
/*check dis*/
.ant-drawer-body{padding: 0px !important;}
/*end*/
.ant-typography.h2-title {
  margin: 20px 0px;
}
.profile-set p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mediatext-center {
  text-align: center;
}
.repbug-bk-color {
  padding: 30px 0px !important;
}
.ant-typography.rep-title {
  font-size: 20px;
}
.rep-header {
  padding: 30px;
}
.notes-ul li {
  margin-right: 10px;
}
.notes-ul a{
  padding: 2px 0px;
}
.notes-div {
  margin: 0px;
  display: inline-flex;
  align-items: center;
}
.notes-page .sideinnertext {
  margin: 0px 0px 20px 0px;
}
.ant-layout-sider {display: none;}
.wrapper.notes-page {
  margin-top: 30px;
}
.mobile_view_menu .ant-menu-submenu-title {
  padding-left: 40px !important;
}
/* .mbs-auto.ant-select.ant-select-enabled {
  width: auto !important;
}
.mbs-100 {
  width: 100px !important;
} */
/* frontmobmenu */
.mob-dis-none{display: none !important;}
.resmenu-clone {
  right: 30px;
  top: 18px;
  left: auto;
}
.mob-dis-show img {
  float: left;
  max-width: 40%;
  display: block !important;
}
/* end frontmobmenu*/
/* ckeditor */
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-border);
  height: 170px;
}
.ck.ck-editor__main>.ck-editor__editable
{
  height: 170px;
}
/* end */
.w-auto {
  width: auto;
}
/* admin console */
.inner_sp{display: block !important;}
/* admin console */
/* .iconn-design.anticon
{
  background: #fff;
  padding: 12px 12px;
  border: 1px solid #d9d9d9;
  transition: all linear .3s;
  border-radius: 5px;
  font-weight: 600;
}
.blue-clr.anticon{margin-right: 0px;}
.up-style {
  padding: 8px 12px !important;
  margin-left: 10px;
} */
}
@media only screen and (min-width: 768px) and (max-width: 1024px)
{
  ul.ant-menu.innersidemenu {
    width: 170px !important;
}
aside.ant-layout-sider.ant-layout-sider-dark
{
  flex: 0 0 200px;
  max-width: 169px !important;
  min-width: 169px !important;
  width: 169px !important;
}
.ck.ck-editor {
  width: 75% !important;
}
.div-sec .c-sec {
  height: 72px;
}
ul.notes-ul {
  margin-top: 10px;
}
.div-sec .div-title {
  overflow: hidden;
  max-height: 40px;
  font-size: 14px;
}
.gx-flex-row.gx-align-items-center.gx-mb-4.gx-avatar-row {
  position: absolute;
  right: 190px;
}
}

@media only screen and (min-width: 768px) and (max-width: 1200px)
{
  .h-100 {
    max-height: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px)
{
  .dpbox .d-none{display: none;}
  .ant-layout-header {padding: 0 0px 0px 0px !important;}
}

@media only screen and (max-width: 1300px)
{ 
  .white_bg {
    margin-left: 0px;
  }
  .login_bk_img {
    padding: 50px 0px 50px 0px;
    }
}

@media only screen and (max-width: 1000px)
{
  .ban-caption 
  {
    top: 11%;
    left: 8%;
  }
  .menuhr-none .ant-menu-item {
    padding: 0px 10px;
}
.media-center{text-align: center;}
  .title-style 
  {
    font-size: 22px;
    font-weight: 600;
    margin-top: 45px;
  }
  .ban-para 
  {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
  }
  .blue-back 
  {
    padding: 30px 0px 0px 0px;
  }
  .blue-title 
  {
    font-size: 22px;
  }
  .pgraph-style 
  {
    font-size: 17px;
    padding: 20px 0px 10px 0px;
    margin: 0px 0px 0px 0px;
  }
  .blue-back img.mediaw-100 
  {
    margin-top: 50px;
  }
  .p-100 
  {
    padding: 0px 0px 50px 0px;
  }
  .m-cont-center
  {
    text-align: center;
  }
  .black-title 
  {
    font-size: 22px;
  }
  .para-graph 
  {
    text-align: center;
  }
  .mt-19 
  {
    margin: 10% 0px;
  }
  .mt-minus.ant-divider-horizontal 
  {
    display: none;
  }
}

@media only screen and (min-width: 768px)
{
  .ban-para {
    width: 55%;
  }
}

@media only screen and (max-width: 1200px)
{
  .mediaw-100{width: 100%;}
  .repbug-bk-color {
    margin-left: 0px;
    padding: 0px;
  }

  .mbs-auto.ant-select.ant-select-enabled {
    width: auto !important;
  }
  .mbs-100 {
    width: 100px !important;
  }
  .iconn-design.anticon
  {
    background: #fff;
    padding: 12px 12px;
    border: 1px solid #d9d9d9;
    transition: all linear .3s;
    border-radius: 5px;
    font-weight: 600;
  }
  .blue-clr.anticon{margin-right: 0px;}
  .up-style {
    padding: 8px 12px !important;
    margin-left: 10px;
  }
  .d-none{display: none;}
  .white-btn {
    padding: 7px 10px;
}
}

@media only screen and (min-width: 768px) and (max-width: 1300px)
{
  .ban-caption {
    top: 12%;
    left: 8%;
}
}

.register-captcha {
  margin-left: 30px !important;
}

#captcha_error { display: none; }
.ant-breadcrumb-link {
  cursor: pointer;
}

#doc_edit_container {
  height: 600px !important;
}

#documenteditor_titlebar {
  margin-top: 64px !important;
}