body {
  margin: 0;
  font-family: 'Nunito' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.register-captcha {
  margin-left: 2px!important;
  margin-bottom: 15px !important;
  margin-top: 10px;
  width: 100%;
}

@media (max-width: 450px){
.register-captcha iframe[title=reCAPTCHA]{
  width: 224px !important;
  height: 60px !important;
  margin-right: 71px;
}
}

