* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.container1 {
  height: 570px;
  overflow-y: scroll;
 
}
.list-item {
  line-height: 2;
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px dashed #fff;
  
}
.audio__title {
  font-size: 14px;
  font-weight: 600;
  /* display: inline-flex; */
  align-items: center;
  color: #404448;
}
._1lB9c {
  display: none !important; 
}
/* ._1ceqH ._1Yplu ._1Pz2d{
  visibility: hidden;
} */
/* ._1ceqH ._1Yplu ._1Pz2d::after{
    content:'goodbye'; 
    outline: 0;
    padding: 10px 20px;
    border-radius: 20px;
    background: #185fec;
    color: #fff;
    cursor: pointer;
    border: 1px solid #185fec;
    transition: .3s ease all;
} */